import {
  useMediaQuery,
  Typography,
  useTheme,
  Collapse,
  Tooltip,
  Button,
  Stack,
  Box,
} from "@mui/material";
import { Cell, Pie, PieChart, Tooltip as RechartsTooltip } from "recharts";
import React, { useRef, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import ThemeToggle from "../../../../components/ToggleGroupeView/ThemeToggle";
import ComponentLoader from "../../../../components/ComponentLoader";
import { formatAmount, remToPx } from "../../../../Helper/data";
import useWidth from "../../../../hooks/useWidth";

const PieWidget = ({
  switchTooltip,
  title,
  loading,
  sx,
  options = [],
  filterOptions = [
    { value: "monthly", label: "label_monthly" },
    { value: "yearly", label: "label_yearly" },
  ],
  onChange,
  value,
  showFilter,
}) => {
  const pieViewRef = useRef(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const currentWidth = useWidth();
  const s1920 = useMediaQuery("(min-width:1920px)");
  const s2133 = useMediaQuery("(min-width:2133px)");
  const s2400 = useMediaQuery("(min-width:2400px)");

  let subtract = 60;
  let defaultWidth = 14;
  if (s1920) {
    subtract = 90;
    defaultWidth = 16;
  }
  if (s2133) {
    subtract = 120;
    defaultWidth = 19;
  }
  if (s2400) {
    defaultWidth = 24;
    subtract = 120;
  }
  const [isExpanded, setIsExpanded] = useState(false);
  const [width, setWidth] = useState(remToPx(currentWidth, defaultWidth));

  const total = useMemo(() => {
    let total = 0;
    options?.forEach((option) => {
      total += option?.value;
    });
    return total;
  }, [options]);

  const onClickShowAll = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    const updateSize = () => {
      if (pieViewRef.current) {
        setWidth(
          pieViewRef.current?.clientWidth || remToPx(currentWidth, defaultWidth)
        );
      }
    };

    updateSize();
    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, [currentWidth, defaultWidth, total, options]);

  const allTotal = formatAmount({ amount: total });
  return (
    <Box sx={sx}>
      {loading ? (
        <ComponentLoader
          loading
          isSkeleton
          height="18rem"
          skeletonCount={1}
          placeHolderHeight="18rem"
          skeltonSx={{
            transform: "scale(1,1)",
          }}
        />
      ) : options?.length > 0 ? (
        <Stack sx={{ width: "100%", height: "auto" }}>
          <Typography
            variant="subtitle1"
            fontWeight={"fontWeightMediumBold"}
            color="color.slate.700"
            display={"flex"}
            alignItems={"start"}
            justifyContent={"space-between"}
            width="100%"
            lineHeight={"normal"}
          >
            {t(title)}{" "}
            {showFilter ? (
              <ThemeToggle
                tooltip={switchTooltip}
                value={value}
                onChange={onChange}
                options={filterOptions}
              />
            ) : null}
          </Typography>
          <Stack direction="row" sx={{ width: "100%", height: "auto" }}>
            <Stack ref={pieViewRef} sx={{ width: "50%", flexWrap: "wrap" }}>
              {width ? (
                <PieChart width={width} height={width}>
                  <Pie
                    data={options}
                    innerRadius={(width - subtract) / 3}
                    outerRadius={(width - subtract) / 2}
                    paddingAngle={0}
                    dataKey="value"
                  >
                    {options?.map((entry) => (
                      <Cell
                        key={`pie-cell-${entry?.uuid}`}
                        fill={entry?.color}
                      />
                    ))}
                    <RechartsTooltip />
                  </Pie>
                </PieChart>
              ) : null}
            </Stack>
            <Stack alignItems="start" sx={{ width: "50%" }}>
              <Typography
                variant={allTotal?.length > 9 ? "h5" : "h4"}
                fontWeight={"fontWeightBold"}
                color="color.slate.700"
                my={2}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                {allTotal}
              </Typography>

              <Collapse
                orientation="vertical"
                in={isExpanded}
                collapsedSize={"7.75rem"}
                sx={{ width: "100%" }}
              >
                {options?.map((item) => (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    key={item?.uuid}
                    my={1}
                    sx={{ width: "100%" }}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "65%",
                      }}
                    >
                      <div
                        style={{
                          width: "1.25rem",
                          height: "1.25rem",
                          backgroundColor: item?.color || "color.slate.200",
                          borderRadius: theme.borderRadius.borderRadiusMD,
                          marginRight: "0.5rem",
                        }}
                      ></div>
                      <Tooltip title={item?.title} placement="top" arrow>
                        <Typography
                          variant="caption"
                          fontWeight={"fontWeightBold"}
                          color="color.description"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "90%",
                            textAlign: "left",
                          }}
                        >
                          {item?.title}
                        </Typography>
                      </Tooltip>
                    </span>

                    <Typography
                      variant="caption"
                      fontWeight={"fontWeightBold"}
                      color="color.description"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "35%",
                        textAlign: "right",
                      }}
                    >
                      {formatAmount({ amount: item?.value })}
                    </Typography>
                  </Stack>
                ))}
              </Collapse>
              {options?.length > 5 ? (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={onClickShowAll}
                  sx={{
                    fontSize: "0.75rem",
                    py: "0.25rem",
                    px: "0.75rem",
                    color: theme.palette.color.slate[800],
                    borderColor: theme.palette.color.slate[300],
                    borderRadius: theme.borderRadius.main,
                  }}
                >
                  {isExpanded ? t("Show Less") : t("Show More")}
                </Button>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      ) : null}
    </Box>
  );
};

export default PieWidget;
