import { useEffect, memo, useMemo, useState, useRef, useCallback } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useMediaQuery, Box } from "@mui/material";
import { BsFillTagsFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

import ClientTransactionsList from "./ClientTransactionsList";
import ThemeTabs from "../../../../components/ThemeTabs";
import ClientHeader from "./ClientHeader";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme?.palette?.color?.appThemeBg,
      height: "100%",
      paddingTop: "1rem",
      width: "100%",
      overflow: "hidden",
    },
  };
});

const Clients = ({ type = 1 }) => {
  const classes = useStyles();
  const viewRef = useRef();
  const timerRef = useRef();
  const s2133 = useMediaQuery("(min-width:2133px)");
  const s2400 = useMediaQuery("(min-width:2400px)");

  let HEIGHT = 2.5;
  let spacing = 110;
  const _width = document.body.clientWidth;
  const width = (_width * 85) / 100;
  if (s2133) {
    HEIGHT = 3;
  }
  if (s2400) {
    HEIGHT = 5;
  }

  //states
  const [tab, setTab] = useState(false);
  const [refreshUI, setRefreshUI] = useState(false);

  const onResize = useCallback(() => {
    if (viewRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setRefreshUI((prev) => !prev);
      }, 100);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  return (
    <Box className={classes.root} ref={viewRef}>
      <ClientHeader type={type} width={width} tab={1} />
      <TabView width={width} type={type} tab={tab} setTab={setTab} />
      {tab === 1 ? (
        <ClientTransactionsList
          type={type}
          width={width}
          HEIGHT={HEIGHT}
          spacing={spacing}
        />
      ) : null}
      <Outlet />
    </Box>
  );
};

export default memo(Clients);

const TabView = ({ width, type, tab, setTab }) => {
  const navigate = useNavigate();
  const location = useLocation();

  //data
  const key = type === 1 ? "customer" : "supplier";
  const url_keys = type === 1 ? "customers" : "suppliers";

  const tabOptions = useMemo(
    () => [
      {
        id: 1,
        url_path: `${url_keys}`,
        value: 1,
        iconPosition: "start",
        label: `${key}_tab_01`,
        icon: <BsFillTagsFill />,
      },
      {
        id: 2,
        url_path: `${url_keys}-list`,
        value: 2,
        iconPosition: "start",
        label: `${key}_tab_02`,
        icon: <BsFillTagsFill />,
      },
      {
        id: 3,
        url_path: `${url_keys}-portfolio-analysis`,
        value: 3,
        iconPosition: "start",
        label: `${key}_tab_03`,
        icon: <BsFillTagsFill />,
      },
      {
        id: 4,
        url_path: `top-${url_keys}`,
        value: 4,
        iconPosition: "start",
        label: `${key}_tab_04`,
        icon: <BsFillTagsFill />,
      },
    ],
    [key, url_keys]
  );

  //redux
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  const handleChangeTab = (event, newValue) => {
    if (tab !== newValue) {
      setTab(newValue);

      const item = tabOptions?.find((o1) => o1?.value === newValue);
      let array = location.pathname.split("/");
      array.pop();
      array.push(item?.url_path);
      let url = array.join("/");
      navigate(url);
    }
  };

  useEffect(() => {
    if (dataset) {
      const array = location.pathname.split("/");
      const url_path = array[array.length - 1];
      const filter = tabOptions?.find((o1) => o1?.url_path === url_path);
      if (filter) {
        setTab(filter?.value);
      }
    }
  }, [location.pathname, dataset, tabOptions, setTab]);

  return (
    <ThemeTabs
      options={tabOptions}
      tab={tab}
      onChange={handleChangeTab}
      sx={{
        width,
        maxWidth: "85%",
        mt: "1rem",
      }}
    />
  );
};
